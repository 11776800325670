<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <filters ref="filters" />
      <b-card no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="fields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
        >
          <!-- Column: Actions -->
          <template #cell(category)="data">
            <span>{{ data.item.category.code }} - {{ data.item.category.description }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Editar'"
              variant="flat-success"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.noninteractive.hover.top="'Eliminar'"
              @click="deleteAction(data.item)"
              variant="flat-danger"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import filters from './filters.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SubCategoryService from '@/services/SubCategoryService'
import TypeWorkService from '@/services/TypeWorkService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      fields: [
        { key: 'division_lesson.description', label: 'División', sortable: false },
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'abbreviation', label: 'Abreviación', sortable: false },
        { key: 'actions', label: 'Acciones' }
      ],
      form: {
        id: null,
        division_lesson_id: null,
        description: '',
        abbreviation: ''
      },
      records: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc'
    }
  },
  components: {
    vSelect,
    filters
  },
  mounted() {
    this.getData()
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    async getData() {
      console.log('**************GET DATA ****************')
      this.records = []
      
      // this.totalElements = 0
      this.showLoading = true
      /* if (this.arrayFilters.length > 0) { */
      console.log('aqui')
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
      const resp = await TypeWorkService.getTypeWorks(url, this.$store)
      console.log({resp:resp})
      if (resp.status) {

        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      /* } */

      this.showLoading = false
    },
    edit(item) {
      console.log('item', item)
      this.form.id = item.id
      this.form.division_lesson_id = item.division_lesson.id
      this.form.description = item.description
      this.form.abbreviation = item.abbreviation
      console.log('this.form', this.form)
      this.$refs.filters.isAdd = true
      this.$refs.filters.$children[0].setData(this.form)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este tipo de obra?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      console.log('id', id)
      const resp = await TypeWorkService.deleteTypeWork(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El tipo de obra ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el tipo de obra seleccionada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
